import { render, staticRenderFns } from "./default.vue?vue&type=template&id=cffb4f96&scoped=true&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=cffb4f96&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cffb4f96",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeader: require('/home/cleavr/www.bchollos.es/releases/20240822032903318/components/layout/Header.vue').default,LayoutFooter: require('/home/cleavr/www.bchollos.es/releases/20240822032903318/components/layout/Footer.vue').default})
