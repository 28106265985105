
import Vue from 'vue'
import { Api } from '~/global'

export default Vue.extend({
    name: 'LayoutFooter',
    data() {
        return {
            subscribing: false,
            skeletons: [
                [1, 2, 3, 4, 5, 6, 7, 8],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4],
                [1, 2, 3, 4, 5, 6, 7],
            ],
            dropdownCountries: [
                {
                    variant: 'megadescuentos',
                    url: 'https://www.megadescuentos.com',
                },
                {
                    variant: 'jdescuentos',
                    url: 'https://www.jdescuentos.cl',
                },
                {
                    variant: 'okdescontos',
                    url: 'https://www.okdescontos.com.br/',
                },
            ],
            form: {
                data: {
                    email: '',
                },
                validationStatus: {
                    email: false as false | string,
                },
            },
            legalLinks: [
                {
                    title: this.$util.lang.components.layoutFooter.privacy_policy,
                    url: '/' + this.$util.lang.routes.privacy,
                },
                {
                    title: this.$util.lang.components.layoutFooter.terms_of_use,
                    url: '/' + this.$util.lang.routes.legal,
                },
                {
                    title: this.$util.lang.components.layoutFooter.cookies_policy,
                    url: '/' + this.$util.lang.routes.cookies,
                },
            ],
        }
    },
    computed: {
        footer(): Api.Responses.General['data']['footer'] | null {
            return this.$store.state.layout?.footer
        },
        rrss(): any {
            return this.$store.state.layout?.footer?.rrss || null
        },
        apps(): any {
            return this.$store.state.layout?.sidebar?.apps || null
        },
    },
    methods: {
        async newSubscription() {
            if (!this.subscribing) {
                try {
                    this.subscribing = true

                    const result = await this.$api.general.newsletterSubscription(this.form.data)

                    if (result.feedback === 'data_success') {
                        this.form.data.email = ''
                        this.form.validationStatus.email = false
                        this.$root.$emit('notification', {
                            text:
                                result.data.message ||
                                this.$util.lang.components.layoutFooter.successSubscription,
                            type: 'success',
                        })
                    } else if (result.feedback === 'existing_email') {
                        this.$root.$emit('notification', {
                            text:
                                result.data.message ||
                                this.$util.lang.components.layoutFooter.emailAlreadyExist,
                            type: 'error',
                        })
                    } else if (result.feedback === 'params_validation_failed') {
                        this.form.validationStatus.email =
                            result.data.email[0] || this.$util.lang.components.layoutFooter.errorOnSubscribing
                    } else if (result.feedback === 'error_mail') {
                        this.form.validationStatus.email =
                            result.data.message || this.$util.lang.components.layoutFooter.errorOnSubscribing
                    }

                    this.subscribing = false
                } catch (e: any) {}
            }
        },
    },
})
