import { Context } from '@nuxt/types'

export default function (ctx: Context) {
    if (ctx.store.getters['auth/isAuth']) {
        return
    }

    if (/^\/(compartir|postar|share)\//.test(ctx.route.path) && ctx.route.path) {
        const finalPath = ctx.route.path.split('/').pop() || ''

        if (
            [
                ctx.$util.lang.routes.chat,
                ctx.$util.lang.routes.coupon,
                ctx.$util.lang.routes.lottery,
                ctx.$util.lang.routes.offer,
            ].includes(finalPath)
        ) {
            ctx.store.commit('setModal', { name: 'ModalAuthLogin' })
            ctx.redirect(302, { path: `/${ctx.$util.lang.routes.share}` })

            return
        }
    }

    ctx.error({
        statusCode: 401,
        message: ctx.$util.lang.middleware.ifAuthenticated.text,
    })
}
