
import Vue, { PropType } from 'vue'

export default Vue.extend({
    name: 'LayoutOverlay',
    props: {
        mode: {
            type: String,
            default: 'normal',
        },
        handler: {
            type: Function as PropType<() => any>,
            default: () => null,
        },
    },
})
