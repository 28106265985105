export default {
    socialStrategy: {
        path: 'https://admin.bchollos.es/api/v1/auth/_RRSS_',
        google: 'https://admin.bchollos.es/api/v1/auth/google/callback',
        facebook: 'https://admin.bchollos.es/api/v1/auth/facebook/callback',
    },
    signUp: {
        path: '/v1/auth/signup',
    },
    verify: {
        path: '/v1/auth/verify',
    },
    signIn: {
        withPassword: {
            path: '/v1/auth/signin',
        },
        withEmailCode: {
            path: '/v1/auth/signin-with-code',
        },
    },
    refresh: {
        path: '/v1/auth/refresh',
    },
    signOut: {
        path: '/v1/auth/logout',
    },
    recoveryInit: {
        withCode: {
            path: '/v1/auth/recover/code',
        },
        withToken: {
            path: '/v1/auth/recover/token',
        },
    },
    recoveryFinish: {
        withToken: {
            path: '/v1/auth/recover-with-token',
        },
    },
}
