import { Context } from '@nuxt/types'

import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex/types'

import { cookies as cookiesKeys } from '~/config/constants'

export const state = () => ({
    allowMarketingCookies: false,
    layout: null as any,
    modal: null as any,
    cookiesModal: null as any,
    headband: null as any,
    banners: null as any,
    modalSecondary: null as any,
    dropdown: null as any,
    serverNotification: null as any,
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
    getCookiesModal: (state) => state.cookiesModal,
    getModal: (state) => state.modal,
    getBanners: (state) => state.banners,
    getLayout: (state) => state.layout,
    getModalSecondary: (state) => state.modalSecondary,
    getDropdown: (state) => state.dropdown,
}

export const mutations: MutationTree<RootState> = {
    setModalSecondary: (state, newModal: any) => (state.modalSecondary = newModal),
    setCookiesModal: (state, newModal: any) => (state.cookiesModal = newModal),
    setModal: (state, newModal: any) => (state.modal = newModal),
    setBanners: (state, newBanners: any) => (state.banners = newBanners),
    setHeadband: (state, headband: any) => (state.headband = headband),
    setDropdown: (state, newDropdown: any) => (state.dropdown = newDropdown),
    setLayout: (state, layout: any) => (state.layout = layout),
    setAllowMarketingCookies: (state, allow: boolean) => (state.allowMarketingCookies = allow),
}

export const actions: ActionTree<RootState, RootState> = {
    assignBanners: (
        context: ActionContext<RootState, RootState>,
        banners: [{ template: string; type: string }]
    ) => {
        const newBanners = { down: {} as any | null, up: {} as any | null }

        banners.forEach((banner) => {
            if (banner.type === 'down') {
                newBanners.down = {
                    name: banner.template === 'custom' ? 'BannerRandom' : 'BannerGeneric',
                    info: banner,
                }
            }
            if (banner.type === 'up') {
                newBanners.up = { name: 'BannerUp', info: banner }
            }
        })
        context.commit('setBanners', newBanners)
    },
    nuxtServerInit: async (_ctx: ActionContext<RootState, RootState>, appCtx: Context) => {
        _ctx.commit('setAllowMarketingCookies', appCtx.$cookies.get(cookiesKeys.cookies_bot_config.marketing))

        try {
            const headband = await appCtx.$api.general.loadHeadband()
            const layoutData = await appCtx.$api.general.loadGeneral()

            if (layoutData && !layoutData.error && layoutData.feedback === 'data_success') {
                _ctx.commit('setLayout', layoutData?.data)
            }
            if (headband && !headband.error && headband.feedback === 'data_success') {
                _ctx.commit('setHeadband', headband.page)
            }
        } catch (e: any) {
            console.log('Store init error: ', { e })
        }
        await appCtx.store.dispatch('auth/authenticationInitializer', appCtx)
    },
}
