import { render, staticRenderFns } from "./User.vue?vue&type=template&id=65b349e9&scoped=true&"
import script from "./User.vue?vue&type=script&lang=ts&"
export * from "./User.vue?vue&type=script&lang=ts&"
import style0 from "./User.vue?vue&type=style&index=0&id=65b349e9&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65b349e9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroUserImage: require('/home/cleavr/www.bchollos.es/releases/20240822032903318/components/micro/userImage.vue').default,DropdownUser: require('/home/cleavr/www.bchollos.es/releases/20240822032903318/components/dropdown/User.vue').default,LayoutOverlay: require('/home/cleavr/www.bchollos.es/releases/20240822032903318/components/layout/Overlay.vue').default})
