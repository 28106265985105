const cookies = {
    access_token_key: 'bch-access-token-key',
    token_expires_at_key: 'bch-token-expiration-key',
    hide_links_warning_key: 'bch-links-warning-key',
    allow_cookies_config: {
        strict_necessary: 'COOKIE_STRICT_NECESSARY',
        personalization: 'COOKIE_PERSONALIZATION',
        functional: 'COOKIE_FUNCTIONAL',
        personalization_copy: 'COOKIE_PERSONALIZATION_COPY',
    },
    cookies_bot_config: {
        strict_necessary: 'COOKIE_NECESSARY',
        preferences: 'COOKIE_PREFERENCES',
        statistics: 'COOKIE_STATISTICS',
        marketing: 'COOKIE_MARKETING',
    },
}

const banners = {
    upBanner: 'bch-top-banner-key',
    downBanner: 'bch-bottom-banner-key',
}

export { cookies, banners }
