
import Vue from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'HeaderComponentSearch',
    props: {
        onOpenHandler: {
            type: Function,
            default: () => null,
        },
    },
    data() {
        return {
            inputsearch: '',
            mounted: false,
            loading: false,
            timer: null as null | NodeJS.Timeout,
            suggests: {
                show: false,
                stores: null as null | Models.Store[],
                categories: null as null | Models.Category[],
                events: null as null | Models.Event[],
            },
            showSearch: true,
            showToggle: true,
        }
    },
    computed: {
        mobile(): boolean {
            return (this.$bp && (this.$bp.mb || this.$bp.xs || this.$bp.sm)) || false
        },
        storesSuggests(): Models.Store[] {
            return this.suggests.stores || this.$store.state.layout?.header?.search?.stores || []
        },
        categoriesSuggests(): Models.Category[] {
            return this.suggests.categories || this.$store.state.layout?.header?.search?.categories || []
        },
        eventsSuggests(): Models.Event[] {
            return this.suggests.events || this.$store.state.layout?.header?.search?.events || []
        },
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                if (this.$route.name !== 'search') {
                    this.inputsearch = ''
                }
                this.showSearch && this.toggleSearch(false)
            },
        },
    },
    mounted() {
        this.showToggle = this.$bp.mb || this.$bp.xs || this.$bp.sm

        this.showSearch = !this.showToggle

        setTimeout(() => (this.mounted = true), 1000)
    },
    methods: {
        toggleSearch(show: boolean) {
            if (this.mobile) {
                if (show) {
                    if (!this.showSearch && !this.suggests.show) {
                        this.showToggle = false
                        this.onOpenHandler(false)
                        this.showSearch = true
                        ;(this.$refs.input as HTMLInputElement)?.focus()
                        this.suggests.show = true
                    }
                } else {
                    ;(this.$refs.input as HTMLInputElement)?.blur()
                    this.inputsearch = ''
                    this.suggests.show = false
                    this.showSearch = false
                    this.suggests.stores = null
                    this.suggests.categories = null
                    this.suggests.events = null
                    this.showToggle = true
                    this.onOpenHandler(true)
                }
            } else if (show) {
                this.suggests.show = true
            } else {
                this.suggests.show = false
                this.suggests.stores = null
                this.suggests.categories = null
                this.suggests.events = null
                this.inputsearch = ''
            }
        },
        onInput(e: Event) {
            const term = (e.target as HTMLInputElement).value

            !this.suggests.show && this.toggleSearch(true)
            if (term.trim().length > 1) {
                this.timer && clearTimeout(this.timer)
                this.timer = setTimeout(async () => {
                    this.loading = true
                    try {
                        const result = await this.$api.general.search(term)
                        if (result && result.feedback === 'data_success') {
                            this.suggests.stores = result.data.stores
                            this.suggests.categories = result.data.categories
                            this.suggests.events = result.data.events
                        }
                    } catch (e: any) {}
                    this.loading = false
                    this.timer = null
                }, 600)
            } else {
                this.suggests.stores = null
                this.suggests.categories = null
                this.suggests.events = null
            }
        },
        includeElement() {
            return [this.$refs.input]
        },
        async onSubmit() {
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }

            const term = (this.$refs.input as HTMLInputElement).value

            if (this.$route.query.q === term) return

            if (term.length > 1) {
                this.$nuxt.$loading.start()

                const searchResult = await this.$api.pages.loadSearch({ search: term })

                if (searchResult.feedback === 'internal_redirect') {
                    if (searchResult.data !== this.$route.path) {
                        this.$router.push({ path: searchResult.data })
                    } else {
                        this.$nuxt.$loading.finish()
                    }
                } else {
                    this.$store.commit('pages/setValue', {
                        name: 'search',
                        page: searchResult,
                    })
                    this.$router.push({ name: 'search', query: { q: term } })
                }
            } else
                this.$root.$emit('notification', {
                    duration: 5000,
                    text: this.$util.lang.components.layoutHeaderSearch.must_min_2_chars,
                    type: 'help',
                    dismissButtonText: this.$util.lang.components.layoutHeaderSearch.okay,
                })
        },
    },
})
