export default {
    privacyPolicy: {
        path: '/v1/pages/privacy-policy',
    },
    legals: {
        path: '/v1/pages/legales',
    },
    jotadealsPolicies: {
        path: '/v1/pages/bchollos-terms-and-policies',
    },
    accessibility: {
        path: '/v1/pages/accessibility',
    },
    copyright: {
        path: '/v1/pages/legal-copyright',
    },
    interestDrivenDeals: {
        path: '/v1/pages/interest-driven-deals',
    },
    ccpaPrivacyNotice: {
        path: '/v1/pages/ccpa-privacy-notice',
    },
    aboutUs: {
        path: '/v1/pages/about-us',
    },
    contactUs: {
        path: '/v1/pages/contact-us',
    },
    cookies: {
        path: '/v1/pages/cookies',
    },
    legalWarning: {
        path: '/v1/pages/legal-warning',
    },
    press: {
        path: '/v1/pages/press',
    },
    job: {
        path: '/v1/pages/job',
    },
    faq: {
        path: '/v1/pages/faq',
    },
    help: {
        path: '/v1/pages/help',
    },
    communityRules: {
        path: '/v1/pages/community-rules',
    },
    contact: {
        path: '/v1/pages/contact',
    },
}
