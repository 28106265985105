import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=8e0aa412&"
import script from "./Footer.vue?vue&type=script&lang=ts&"
export * from "./Footer.vue?vue&type=script&lang=ts&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=8e0aa412&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarSocialNetworksLazy: require('/home/cleavr/www.bchollos.es/releases/20240822032903318/components/sidebar/SocialNetworksLazy.vue').default,SidebarGiveMe: require('/home/cleavr/www.bchollos.es/releases/20240822032903318/components/sidebar/GiveMe.vue').default,DropdownLocation: require('/home/cleavr/www.bchollos.es/releases/20240822032903318/components/dropdown/Location.vue').default})
