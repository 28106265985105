import lang from '../lang/es'

const processEnvMode = (process.env.ENV_MODE || 'development') as 'development' | 'production'

const siteLanguage = {
    env: 'es' as 'es',
    code: 'es-ES' as 'es-ES',
}

const siteStaticRoutesToIndex = [
    {
        url: '/' + lang.routes.about_us,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.rules,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.blog,
        priority: 0.7,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.share,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.contact,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.coupons,
        priority: 0.6,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.events,
        priority: 0.7,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.expired,
        priority: 0.7,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    /*  {
        url: '/' + lang.routes.extension,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    }, */
    {
        url: '/' + lang.routes.faq,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.forum,
        priority: 0.7,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.free,
        priority: 0.7,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.novelties,
        priority: 0.5,
        changefreq: 'weekly',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.offer,
        priority: 0.6,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.register,
        priority: 0.1,
        changefreq: 'monthly',
        lastmod: new Date(),
    },
    {
        url: '/' + lang.routes.lotteries,
        priority: 0.7,
        changefreq: 'daily',
        lastmod: new Date(),
    },
    {
        url: '/',
        priority: 1,
        changefreq: 'hourly',
        lastmod: new Date(),
    },
]

const siteFavicon = '/favicon/bchollos.png'

const siteName = 'Bchollos'

const siteOrigin = 'https://www.bchollos.es'

const siteFinalHost = 'www.bchollos.es'

const siteBaseURL = {
    development: 'https://sandbox-api.okdescuentos.com/api',

    production: 'https://admin.bchollos.es/api',
}

const siteSitemapsPrefixes = {
    general: {
        novelties: `/${lang.routes.novelties}/`,
        articles: `/${lang.routes.blog}/`,
        events: `/${lang.routes.events}/`,
    },
    stores: `/${lang.routes.brands}/`,
    categories: `/${lang.routes.categories}/`,
    chats: `/${lang.routes.forum}/`,
    offers: `/${lang.routes.offer}/`,
    coupons: `/${lang.routes.coupons}/`,
}

const robotsDisallowUrls = [
    `/${lang.routes.search}`,
    `/${lang.routes.login}`,
    `/${lang.routes.dashboard}`,
    `/${lang.routes.share}/*`,
    '*?slug=*',
    '/cdn-cgi/*',
    '/goprize',
    '/local',
    '/assets',
    '/_nuxt',
    '/open',
    '/go',
    '/lp',
    '/js',
]

const GTMKeys = 'GTM-N25ZD8Z7'

const defaultMetaTags = [
    {
        name: 'google-site-verification',
        content: 'bi9t1osp8emNaj7rz1jHW-Ur0ObgTMPMn6PZ3F7aHzs',
    },
    {
        name: 'ir-site-verification-token',
        content: '-378293540',
    },
    {
        name: 'verify-admitad',
        content: '87c5608d9e',
    },
    {
        hid: 'og:image',
        property: 'og:image',
        content: /* siteOrigin + */ `/assets/jpg/bchollos/preview.jpg`,
    },
    {
        hid: 'og:image:secure_url',
        property: 'og:image:secure_url',
        content: /* siteOrigin + */ `/assets/jpg/bchollos/preview.jpg`,
    },
    {
        hid: 'twitter:image',
        property: 'twitter:image',
        content: /* siteOrigin + */ `/assets/jpg/bchollos/preview.jpg`,
    },
    {
        hid: 'og:site_name',
        property: 'og:site_name',
        content: siteName,
    },
    {
        hid: 'og:type',
        property: 'og:type',
        content: 'website',
    },
    {
        hid: 'og:locale',
        property: 'og:locale',
        content: siteLanguage.code,
    },
    {
        hid: 'twitter:card',
        property: 'twitter:card',
        content: 'summary',
    },
]

export const variantConfig = {
    siteFinalHost,
    siteName,
    siteFavicon,
    siteOrigin,
    siteStaticRoutesToIndex,
    siteSitemapsPrefixes,
    robotsDisallowUrls,
    siteVariant: 'bchollos',
    siteMode: processEnvMode,
    siteBaseURL: siteBaseURL[processEnvMode],
    siteLang: siteLanguage,
    GTMKey: GTMKeys,
    metaTags: defaultMetaTags,
}
