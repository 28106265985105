import { VueConstructor } from 'vue/types'

declare module 'vue/types/vue' {
    interface Vue {
        $bp: {
            mb: boolean
            xs: boolean
            sm: boolean
            md: boolean
            lg: boolean
            xl: boolean
            current: 'mb' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
        }
    }
}

export default {
    install: (Vue: VueConstructor) => {
        const status = {
            mb: null as null | boolean,
            xs: null as null | boolean,
            sm: null as null | boolean,
            md: null as null | boolean,
            lg: null as null | boolean,
            xl: null as null | boolean,
            current: null as null | string,
        }

        const mediaQueries = {
            mb: matchMedia('(max-width: 479px)'),
            xs: matchMedia('(min-width: 480px) and (max-width: 639px)'),
            sm: matchMedia('(min-width: 600px) and (max-width: 767px)'),
            md: matchMedia('(min-width: 768px) and (max-width: 1023px)'),
            lg: matchMedia('(min-width: 1024px) and (max-width: 1199px)'),
            xl: matchMedia('(min-width: 1200px)'),
        }

        status.current = mediaQueries.mb.matches
            ? 'mb'
            : mediaQueries.xs.matches
            ? 'xs'
            : mediaQueries.sm.matches
            ? 'sm'
            : mediaQueries.md.matches
            ? 'md'
            : mediaQueries.lg.matches
            ? 'lg'
            : 'xl'

        status.mb = mediaQueries.mb.matches
        status.xs = mediaQueries.xs.matches
        status.sm = mediaQueries.sm.matches
        status.md = mediaQueries.md.matches
        status.lg = mediaQueries.lg.matches
        status.xl = mediaQueries.xl.matches

        mediaQueries.mb.addEventListener('change', function (e) {
            status.mb = e.matches
            e.matches && (status.current = 'mb')
        })

        mediaQueries.xs.addEventListener('change', function (e) {
            status.xs = e.matches
            e.matches && (status.current = 'xs')
        })

        mediaQueries.sm.addEventListener('change', function (e) {
            status.sm = e.matches
            e.matches && (status.current = 'sm')
        })

        mediaQueries.md.addEventListener('change', function (e) {
            status.md = e.matches
            e.matches && (status.current = 'md')
        })

        mediaQueries.lg.addEventListener('change', function (e) {
            status.lg = e.matches
            e.matches && (status.current = 'lg')
        })

        mediaQueries.xl.addEventListener('change', function (e) {
            status.xl = e.matches
            e.matches && (status.current = 'xl')
        })

        Vue.prototype.$bp = Vue.observable(status)
    },
}
