import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67ec183b = () => interopDefault(import('../pages/accessibility.vue' /* webpackChunkName: "pages/accessibility" */))
const _3841a564 = () => interopDefault(import('../pages/acerca-de.vue' /* webpackChunkName: "pages/acerca-de" */))
const _22ad2fee = () => interopDefault(import('../pages/aviso-legal.vue' /* webpackChunkName: "pages/aviso-legal" */))
const _1815b267 = () => interopDefault(import('../pages/ayuda.vue' /* webpackChunkName: "pages/ayuda" */))
const _2af34108 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _b204e662 = () => interopDefault(import('../pages/cambiar-contrasena.vue' /* webpackChunkName: "pages/cambiar-contrasena" */))
const _1887ed34 = () => interopDefault(import('../pages/ccpa-privacy-notice.vue' /* webpackChunkName: "pages/ccpa-privacy-notice" */))
const _9c078898 = () => interopDefault(import('../views/StoresBridge.vue' /* webpackChunkName: "" */))
const _5a16acc7 = () => interopDefault(import('../views/Homepage.vue' /* webpackChunkName: "" */))
const _b07b83e6 = () => interopDefault(import('../pages/compartir/index.vue' /* webpackChunkName: "pages/compartir/index" */))
const _ed1c521c = () => interopDefault(import('../pages/contacto.vue' /* webpackChunkName: "pages/contacto" */))
const _356724ca = () => interopDefault(import('../pages/contrasena-actualizada-exitosamente.vue' /* webpackChunkName: "pages/contrasena-actualizada-exitosamente" */))
const _6aad74bc = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _07f362bb = () => interopDefault(import('../pages/cupones/index.vue' /* webpackChunkName: "pages/cupones/index" */))
const _a3b7ba78 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _a9d6f372 = () => interopDefault(import('../pages/dashboard/index/index.vue' /* webpackChunkName: "pages/dashboard/index/index" */))
const _abd6ac9c = () => interopDefault(import('../pages/dashboard/index/actividad.vue' /* webpackChunkName: "pages/dashboard/index/actividad" */))
const _5ed83020 = () => interopDefault(import('../pages/dashboard/index/ajustes.vue' /* webpackChunkName: "pages/dashboard/index/ajustes" */))
const _3cd26910 = () => interopDefault(import('../pages/dashboard/index/chats.vue' /* webpackChunkName: "pages/dashboard/index/chats" */))
const _630a09e6 = () => interopDefault(import('../pages/dashboard/index/descuentos.vue' /* webpackChunkName: "pages/dashboard/index/descuentos" */))
const _7bc3a93e = () => interopDefault(import('../pages/dashboard/index/guardados.vue' /* webpackChunkName: "pages/dashboard/index/guardados" */))
const _7f54ead2 = () => interopDefault(import('../pages/dashboard/index/monedas.vue' /* webpackChunkName: "pages/dashboard/index/monedas" */))
const _43e3d758 = () => interopDefault(import('../pages/dashboard/index/premios.vue' /* webpackChunkName: "pages/dashboard/index/premios" */))
const _9e82e740 = () => interopDefault(import('../pages/dashboard/index/sorteos.vue' /* webpackChunkName: "pages/dashboard/index/sorteos" */))
const _364acdc4 = () => interopDefault(import('../views/CategoriesBridge.vue' /* webpackChunkName: "" */))
const _5ccdbd3b = () => interopDefault(import('../pages/do-not-sell.vue' /* webpackChunkName: "pages/do-not-sell" */))
const _9edf8224 = () => interopDefault(import('../pages/eventos/index.vue' /* webpackChunkName: "pages/eventos/index" */))
const _2fc0603a = () => interopDefault(import('../pages/expirados.vue' /* webpackChunkName: "pages/expirados" */))
const _6176177a = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _55c35bdd = () => interopDefault(import('../pages/foros/index.vue' /* webpackChunkName: "pages/foros/index" */))
const _4ece5e2a = () => interopDefault(import('../pages/gratis.vue' /* webpackChunkName: "pages/gratis" */))
const _128a6060 = () => interopDefault(import('../pages/inicio-sin-contrasena.vue' /* webpackChunkName: "pages/inicio-sin-contrasena" */))
const _ecce1664 = () => interopDefault(import('../pages/interest-driven-deals.vue' /* webpackChunkName: "pages/interest-driven-deals" */))
const _78c6be27 = () => interopDefault(import('../pages/jotadeals-terms-and-policies.vue' /* webpackChunkName: "pages/jotadeals-terms-and-policies" */))
const _9aa2fe40 = () => interopDefault(import('../pages/legal-copyright.vue' /* webpackChunkName: "pages/legal-copyright" */))
const _64f082d7 = () => interopDefault(import('../pages/legales/index.vue' /* webpackChunkName: "pages/legales/index" */))
const _e63a3454 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _22e25486 = () => interopDefault(import('../views/InstallExtension.vue' /* webpackChunkName: "" */))
const _9d43089e = () => interopDefault(import('../pages/mensajeria/index.vue' /* webpackChunkName: "pages/mensajeria/index" */))
const _68ecdda6 = () => interopDefault(import('../pages/notificaciones.vue' /* webpackChunkName: "pages/notificaciones" */))
const _3e8e4735 = () => interopDefault(import('../pages/novedades/index.vue' /* webpackChunkName: "pages/novedades/index" */))
const _4ee05bad = () => interopDefault(import('../pages/nueva-contrasena.vue' /* webpackChunkName: "pages/nueva-contrasena" */))
const _4c55af5c = () => interopDefault(import('../pages/ofertas/index.vue' /* webpackChunkName: "pages/ofertas/index" */))
const _fbdcc444 = () => interopDefault(import('../pages/olvide-mi-contrasena.vue' /* webpackChunkName: "pages/olvide-mi-contrasena" */))
const _1f3e697f = () => interopDefault(import('../pages/politicas-de-privacidad.vue' /* webpackChunkName: "pages/politicas-de-privacidad" */))
const _36b51ea2 = () => interopDefault(import('../views/Preferences.vue' /* webpackChunkName: "" */))
const _3d402bc8 = () => interopDefault(import('../pages/prensa.vue' /* webpackChunkName: "pages/prensa" */))
const _26a3d654 = () => interopDefault(import('../pages/registro.vue' /* webpackChunkName: "pages/registro" */))
const _75a9661c = () => interopDefault(import('../pages/reglas-de-comunidad.vue' /* webpackChunkName: "pages/reglas-de-comunidad" */))
const _4febee8b = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _4886f0e5 = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _ed5b10b0 = () => interopDefault(import('../pages/sitemap/index/index.vue' /* webpackChunkName: "pages/sitemap/index/index" */))
const _77853edb = () => interopDefault(import('../pages/sitemap/index/promociones/index.vue' /* webpackChunkName: "pages/sitemap/index/promociones/index" */))
const _3a20c551 = () => interopDefault(import('../pages/sitemap/index/tiendas/index.vue' /* webpackChunkName: "pages/sitemap/index/tiendas/index" */))
const _75ce9d93 = () => interopDefault(import('../pages/sitemap/index/promociones/_slug.vue' /* webpackChunkName: "pages/sitemap/index/promociones/_slug" */))
const _9ca26e92 = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _06024d04 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _5095cbad = () => interopDefault(import('../pages/verificar-cuenta.vue' /* webpackChunkName: "pages/verificar-cuenta" */))
const _5c933ded = () => interopDefault(import('../pages/compartir/chat.vue' /* webpackChunkName: "pages/compartir/chat" */))
const _dfa9d64a = () => interopDefault(import('../pages/compartir/cupon/index.vue' /* webpackChunkName: "pages/compartir/cupon/index" */))
const _ac99d70c = () => interopDefault(import('../pages/compartir/finalizado.vue' /* webpackChunkName: "pages/compartir/finalizado" */))
const _c50ab9ee = () => interopDefault(import('../pages/compartir/oferta/index.vue' /* webpackChunkName: "pages/compartir/oferta/index" */))
const _1a7d7a1d = () => interopDefault(import('../pages/compartir/sorteo.vue' /* webpackChunkName: "pages/compartir/sorteo" */))
const _38590be0 = () => interopDefault(import('../pages/compartir/cupon/crear.vue' /* webpackChunkName: "pages/compartir/cupon/crear" */))
const _1db9ef84 = () => interopDefault(import('../pages/compartir/oferta/crear.vue' /* webpackChunkName: "pages/compartir/oferta/crear" */))
const _418da664 = () => interopDefault(import('../views/StoreMask.vue' /* webpackChunkName: "" */))
const _27f33656 = () => interopDefault(import('../pages/blog/_category/index.vue' /* webpackChunkName: "pages/blog/_category/index" */))
const _a2959110 = () => interopDefault(import('../views/DiscountDetails.vue' /* webpackChunkName: "" */))
const _586e1ac8 = () => interopDefault(import('../pages/dashboard/_user.vue' /* webpackChunkName: "pages/dashboard/_user" */))
const _5c37d7c2 = () => interopDefault(import('../pages/dashboard/_user/index.vue' /* webpackChunkName: "pages/dashboard/_user/index" */))
const _bf8ce8ec = () => interopDefault(import('../pages/dashboard/_user/actividad.vue' /* webpackChunkName: "pages/dashboard/_user/actividad" */))
const _63a1f6e8 = () => interopDefault(import('../pages/dashboard/_user/chats.vue' /* webpackChunkName: "pages/dashboard/_user/chats" */))
const _3181630e = () => interopDefault(import('../pages/dashboard/_user/descuentos.vue' /* webpackChunkName: "pages/dashboard/_user/descuentos" */))
const _a24cc4b4 = () => interopDefault(import('../pages/eventos/_slug.vue' /* webpackChunkName: "pages/eventos/_slug" */))
const _540cba95 = () => interopDefault(import('../pages/foros/_slug.vue' /* webpackChunkName: "pages/foros/_slug" */))
const _3dac96b4 = () => interopDefault(import('../views/DiscountMask.vue' /* webpackChunkName: "" */))
const _6339e18f = () => interopDefault(import('../pages/legales/_slug.vue' /* webpackChunkName: "pages/legales/_slug" */))
const _3cd7a5ed = () => interopDefault(import('../pages/novedades/_slug.vue' /* webpackChunkName: "pages/novedades/_slug" */))
const _02c8c763 = () => interopDefault(import('../pages/blog/_category/_article.vue' /* webpackChunkName: "pages/blog/_category/_article" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessibility",
    component: _67ec183b,
    name: "accessibility"
  }, {
    path: "/acerca-de",
    component: _3841a564,
    name: "acerca-de"
  }, {
    path: "/aviso-legal",
    component: _22ad2fee,
    name: "aviso-legal"
  }, {
    path: "/ayuda",
    component: _1815b267,
    name: "ayuda"
  }, {
    path: "/blog",
    component: _2af34108,
    name: "blog"
  }, {
    path: "/cambiar-contrasena",
    component: _b204e662,
    name: "cambiar-contrasena"
  }, {
    path: "/ccpa-privacy-notice",
    component: _1887ed34,
    name: "ccpa-privacy-notice"
  }, {
    path: "/codigos-descuento",
    component: _9c078898,
    name: "codigos-descuento"
  }, {
    path: "/comentados",
    component: _5a16acc7,
    name: "index-comentados"
  }, {
    path: "/compartir",
    component: _b07b83e6,
    name: "compartir"
  }, {
    path: "/contacto",
    component: _ed1c521c,
    name: "contacto"
  }, {
    path: "/contrasena-actualizada-exitosamente",
    component: _356724ca,
    name: "contrasena-actualizada-exitosamente"
  }, {
    path: "/cookies",
    component: _6aad74bc,
    name: "cookies"
  }, {
    path: "/cupones",
    component: _07f362bb,
    name: "cupones"
  }, {
    path: "/dashboard",
    component: _a3b7ba78,
    children: [{
      path: "",
      component: _a9d6f372,
      name: "dashboard-index"
    }, {
      path: "actividad",
      component: _abd6ac9c,
      name: "dashboard-index-actividad"
    }, {
      path: "ajustes",
      component: _5ed83020,
      name: "dashboard-index-ajustes"
    }, {
      path: "chats",
      component: _3cd26910,
      name: "dashboard-index-chats"
    }, {
      path: "descuentos",
      component: _630a09e6,
      name: "dashboard-index-descuentos"
    }, {
      path: "guardados",
      component: _7bc3a93e,
      name: "dashboard-index-guardados"
    }, {
      path: "monedas",
      component: _7f54ead2,
      name: "dashboard-index-monedas"
    }, {
      path: "premios",
      component: _43e3d758,
      name: "dashboard-index-premios"
    }, {
      path: "sorteos",
      component: _9e82e740,
      name: "dashboard-index-sorteos"
    }]
  }, {
    path: "/descuentos",
    component: _364acdc4,
    name: "descuentos"
  }, {
    path: "/do-not-sell",
    component: _5ccdbd3b,
    name: "do-not-sell"
  }, {
    path: "/eventos",
    component: _9edf8224,
    name: "eventos"
  }, {
    path: "/expirados",
    component: _2fc0603a,
    name: "expirados"
  }, {
    path: "/faq",
    component: _6176177a,
    name: "faq"
  }, {
    path: "/foros",
    component: _55c35bdd,
    name: "foros"
  }, {
    path: "/gratis",
    component: _4ece5e2a,
    name: "gratis"
  }, {
    path: "/inicio-sin-contrasena",
    component: _128a6060,
    name: "inicio-sin-contrasena"
  }, {
    path: "/interest-driven-deals",
    component: _ecce1664,
    name: "interest-driven-deals"
  }, {
    path: "/jotadeals-terms-and-policies",
    component: _78c6be27,
    name: "jotadeals-terms-and-policies"
  }, {
    path: "/legal-copyright",
    component: _9aa2fe40,
    name: "legal-copyright"
  }, {
    path: "/legales",
    component: _64f082d7,
    name: "legales"
  }, {
    path: "/login",
    component: _e63a3454,
    name: "login"
  }, {
    path: "/lp",
    component: _22e25486,
    name: "lp"
  }, {
    path: "/mensajeria",
    component: _9d43089e,
    name: "mensajeria"
  }, {
    path: "/notificaciones",
    component: _68ecdda6,
    name: "notificaciones"
  }, {
    path: "/novedades",
    component: _3e8e4735,
    name: "novedades"
  }, {
    path: "/nueva-contrasena",
    component: _4ee05bad,
    name: "nueva-contrasena"
  }, {
    path: "/nuevos",
    component: _5a16acc7,
    name: "index-nuevos"
  }, {
    path: "/ofertas",
    component: _4c55af5c,
    name: "ofertas"
  }, {
    path: "/olvide-mi-contrasena",
    component: _fbdcc444,
    name: "olvide-mi-contrasena"
  }, {
    path: "/politicas-de-privacidad",
    component: _1f3e697f,
    name: "politicas-de-privacidad"
  }, {
    path: "/preferencias",
    component: _36b51ea2,
    name: "preferences"
  }, {
    path: "/prensa",
    component: _3d402bc8,
    name: "prensa"
  }, {
    path: "/registro",
    component: _26a3d654,
    name: "registro"
  }, {
    path: "/reglas-de-comunidad",
    component: _75a9661c,
    name: "reglas-de-comunidad"
  }, {
    path: "/search",
    component: _4febee8b,
    name: "search"
  }, {
    path: "/sitemap",
    component: _4886f0e5,
    children: [{
      path: "",
      component: _ed5b10b0,
      name: "sitemap-index"
    }, {
      path: "promociones",
      component: _77853edb,
      name: "sitemap-index-promociones"
    }, {
      path: "tiendas",
      component: _3a20c551,
      name: "sitemap-index-tiendas"
    }, {
      path: "promociones/:slug?",
      component: _75ce9d93,
      name: "sitemap-index-promociones-slug"
    }]
  }, {
    path: "/subscribe",
    component: _9ca26e92,
    name: "subscribe"
  }, {
    path: "/unsubscribe",
    component: _06024d04,
    name: "unsubscribe"
  }, {
    path: "/verificar-cuenta",
    component: _5095cbad,
    name: "verificar-cuenta"
  }, {
    path: "/compartir/chat",
    component: _5c933ded,
    name: "compartir-chat"
  }, {
    path: "/compartir/cupon",
    component: _dfa9d64a,
    name: "compartir-cupon"
  }, {
    path: "/compartir/finalizado",
    component: _ac99d70c,
    name: "compartir-finalizado"
  }, {
    path: "/compartir/oferta",
    component: _c50ab9ee,
    name: "compartir-oferta"
  }, {
    path: "/compartir/sorteo",
    component: _1a7d7a1d,
    name: "compartir-sorteo"
  }, {
    path: "/compartir/cupon/crear",
    component: _38590be0,
    name: "compartir-cupon-crear"
  }, {
    path: "/compartir/oferta/crear",
    component: _1db9ef84,
    name: "compartir-oferta-crear"
  }, {
    path: "/codigos-descuento/letra/:slug",
    component: _9c078898,
    name: "codigos-descuento-letra-slug"
  }, {
    path: "/descuentos/buscar/:slug",
    component: _364acdc4,
    name: "descuentos-buscar-slug"
  }, {
    path: "/go/codigos-descuento/:id",
    component: _418da664,
    name: "go-store-slug"
  }, {
    path: "/blog/:category",
    component: _27f33656,
    name: "blog-category"
  }, {
    path: "/codigos-descuento/:slug",
    component: _9c078898,
    name: "codigos-descuento-slug"
  }, {
    path: "/cupones/:slug",
    component: _a2959110,
    name: "cupones-slug"
  }, {
    path: "/dashboard/:user",
    component: _586e1ac8,
    children: [{
      path: "",
      component: _5c37d7c2,
      name: "dashboard-user"
    }, {
      path: "actividad",
      component: _bf8ce8ec,
      name: "dashboard-user-actividad"
    }, {
      path: "chats",
      component: _63a1f6e8,
      name: "dashboard-user-chats"
    }, {
      path: "descuentos",
      component: _3181630e,
      name: "dashboard-user-descuentos"
    }]
  }, {
    path: "/descuentos/:slug",
    component: _364acdc4,
    name: "descuentos-slug"
  }, {
    path: "/eventos/:slug",
    component: _a24cc4b4,
    name: "eventos-slug"
  }, {
    path: "/foros/:slug",
    component: _540cba95,
    name: "foros-slug"
  }, {
    path: "/go/:id",
    component: _3dac96b4,
    name: "go-id"
  }, {
    path: "/goprize/:hash",
    component: _3dac96b4,
    name: "goprize-hash"
  }, {
    path: "/legales/:slug",
    component: _6339e18f,
    name: "legales-slug"
  }, {
    path: "/lp/:slug",
    component: _22e25486,
    name: "lp-slug"
  }, {
    path: "/novedades/:slug",
    component: _3cd7a5ed,
    name: "novedades-slug"
  }, {
    path: "/ofertas/:slug",
    component: _a2959110,
    name: "ofertas-slug"
  }, {
    path: "/open/:slug",
    component: _3dac96b4,
    name: "open-slug"
  }, {
    path: "/blog/:category/:article",
    component: _02c8c763,
    name: "blog-category-article"
  }, {
    path: "/",
    component: _5a16acc7,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
