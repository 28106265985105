import merge from '~lodash.mergewith'
import { init, Replay } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F411fd953766843963c3de97b3f68c778@o867892.ingest.sentry.io\u002F4505879160619008",
    environment:"production",
    sampleRate:0.25,
    tracesSampleRate:0.25,
    ignoreErrors:["ReportingObserver","Cannot read properties of undefined (reading 'store')","Cannot read properties of undefined (reading 'description')","Cannot read properties of undefined (reading 'title')","Failed to read the 'localStorage' property from 'Window': Access is denied for this document.","Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'https:\u002F\u002Fsearchaggr-dra.dt.dbankcloud.com\u002Fsearch\u002Fapi\u002Fv1\u002Fwebtranslation_detect'.","ResizeObserver loop completed with undelivered notifications.","NotAllowedError: Document is not focused.","Failed to execute 'appendChild' on 'Node': This node type does not support this method."],
    replaysSessionSampleRate:0,
    replaysOnErrorSampleRate:1,
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
    new Replay(),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
